import 'lazysizes/plugins/respimg/ls.respimg.js';
import 'lazysizes';
import { toggleSearch, toggleMobileMenu } from './header';
import { init as initCarousel } from './slider';
import { diAccordion } from './accordion';
import { yearsFilter } from './ui-elements';
import { init as initClickToGo } from './click-to-go';
import { init as initDeviationForm } from './deviation-form';

if (document.readyState !== 'loading') {
	initJavaScript();
} else {
	document.addEventListener('DOMContentLoaded', initJavaScript);
}

function initJavaScript() {
	document.querySelectorAll('.search-button-container-main').forEach(toggleSearch);
	document.querySelectorAll('.menu-btn-toggle').forEach(toggleMobileMenu);
	document.querySelectorAll('[aria-roledescription="carousel"]').forEach(initCarousel);
	document.querySelectorAll('.di-accordion .di-accordion-item').forEach(diAccordion);
	document.querySelectorAll('.years-filter').forEach(yearsFilter);
	document.querySelectorAll('[data-click-to-go]').forEach(initClickToGo);
	document.querySelectorAll('.deviation-form').forEach(initDeviationForm);
}

function toggleDiplomisbilenInput() {
    var select = document.getElementById("purchasedWhere");

    var receiptLabel = document.querySelector("#receiptNumberLabel");
    var truckLabel = document.querySelector("#truckNumberLabel");

    // Check if the selected option is "Diplom-is bilen" so we can show
    if (select.value === "Diplom-is bilen") {
        // Show the input field
        receiptLabel.classList.remove("input-hidden");
        truckLabel.classList.remove("input-hidden");
    } else {
        // Hide the input field
        receiptLabel.classList.add("input-hidden");
        truckLabel.classList.add("input-hidden");
    }
}

window.toggleDiplomisbilenInput = toggleDiplomisbilenInput;
