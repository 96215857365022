const mobileSubmitEL = document.querySelector('.mobile-search-submit');
const mobileOverlayEL = document.querySelector('.mobile--overlay');

export function toggleSearch(el) {
	const formEL = el.querySelector('form');

	if (formEL) {
		formEL.classList.add('hidden');
	}

	const mediaQuery = el.dataset.mediaQuery;

	if (mediaQuery === 'desktop') {
		const buttonSearchEL = document.createElement('button');
		buttonSearchEL.classList.add('search-btn-toggle');
		buttonSearchEL.classList.add('di-header-main__search-button');
		buttonSearchEL.classList.add('showHideSearch');
		buttonSearchEL.classList.add('di-header-menu__menu-link');
		buttonSearchEL.innerHTML = 'Søk';
		el.append(buttonSearchEL);
	}

	if (mediaQuery === 'mobile') {
		const buttonSearchMobileEL = document.createElement('button');
		buttonSearchMobileEL.classList.add('search-button');
		buttonSearchMobileEL.classList.add('hidden-lg');
		buttonSearchMobileEL.classList.add('showHideSearch');
		buttonSearchMobileEL.setAttribute('aria-label', 'Søk');
		el.prepend(buttonSearchMobileEL);
	}

	if (mobileSubmitEL) {
		mobileSubmitEL.classList.add('hidden');
	}

	const toggleButtonsEl = el.querySelectorAll('.showHideSearch');

	toggleButtonsEl.forEach((el) => {
		el.addEventListener('click', function(event) {
			event.preventDefault();
			const elHidden = document.querySelector('.di-search');
			if (mobileOverlayEL) {
				mobileOverlayEL.classList.toggle('hidden');
			}
			elHidden.classList.toggle('hidden');
		});
	});
}

export function toggleMobileMenu(el) {
	el.addEventListener('click', function() {
		event.preventDefault();
		const elMenuList = document.querySelector('.di-header-menu');
		elMenuList.classList.toggle('displayMenu-false');
		if (mobileOverlayEL) {
			mobileOverlayEL.classList.toggle('hidden');
		}
		el.classList.toggle('di-header-main__hamburger-expanded');
	});
}
