/**
 * @param {HTMLElement} el
 */
export function init(el) {
	el.style.cursor = 'pointer';
	el.addEventListener('click', (e) => {
		const url = el.dataset.clickToGo;
		if (e.target.nodeName !== 'A' && url) {
			window.location.href = url;
		}
	});
}
