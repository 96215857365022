export function diAccordion(accordionEl) {
	const labelEl = accordionEl.querySelector('.di-accordion-item__label');
	const bodyEl = accordionEl.querySelector('.di-accordion-item__body');

	if (labelEl && bodyEl) {
		labelEl.setAttribute('role', 'button');
		labelEl.setAttribute('tabindex', '0');
		labelEl.setAttribute('aria-expanded', 'false');
		labelEl.setAttribute('aria-controls', labelEl.dataset.controls);
		bodyEl.classList.add('hidden');

		labelEl.addEventListener('click', () => toggleVisible(labelEl, bodyEl));
		labelEl.addEventListener('keyup', (e) => {
			switch (e.code) {
				case 'Space':
				case 'Enter':
					toggleVisible(labelEl, bodyEl);
			}
		});
	}
}

function toggleVisible(labelEl, bodyEl) {
	const isExpanded = labelEl.getAttribute('aria-expanded') === 'true';
	labelEl.setAttribute('aria-expanded', (!isExpanded).toString());
	bodyEl.classList.toggle('hidden', isExpanded);
	labelEl.classList.toggle('di-accordion-item__label--active', !isExpanded);
}
