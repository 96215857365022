export function init(carouselEl) {
	const previousBtnEl = carouselEl.querySelector('.arrows__previous');
	const nextBtnEl = carouselEl.querySelector('.arrows__next');
	const slideEls = carouselEl.querySelectorAll('[aria-roledescription="slide"]');

	let currentSlide = 0;

	// init buttons
	toggleButtons(previousBtnEl, nextBtnEl, currentSlide, slideEls.length - 1);

	if (previousBtnEl) {
		previousBtnEl.addEventListener('click', () => {
			if (currentSlide !== 0) {
				currentSlide = currentSlide - 1;
				setActiveSlide(slideEls, currentSlide);
				toggleButtons(previousBtnEl, nextBtnEl, currentSlide, slideEls.length - 1);
			}
		});
	}

	if (nextBtnEl) {
		nextBtnEl.addEventListener('click', () => {
			if (currentSlide < slideEls.length - 1) {
				currentSlide = currentSlide + 1;
				setActiveSlide(slideEls, currentSlide);
				toggleButtons(previousBtnEl, nextBtnEl, currentSlide, slideEls.length - 1);
			}
		});
	}

	if (slideEls[0]) {
		slideEls[0].classList.add('slide__active');
	}
}

/**
 * Display the current slide
 *
 * @param {NodeListOf<HTMLElement>} slideEls
 * @param {number} active
 */
function setActiveSlide(slideEls, active) {
	slideEls.forEach((el, index) => toggleClass(el, 'slide__active', index === active));
}

/**
 * Set the buttons inactive current slide is on the edge
 *
 * @param {HTMLButtonElement} previousBtnEl
 * @param {HTMLButtonElement} nextBtnEl
 * @param {number} currentSlide
 * @param {number} lastIndex
 */
function toggleButtons(previousBtnEl, nextBtnEl, currentSlide, lastIndex) {
	toggleButtonDisabled(previousBtnEl, currentSlide === 0);
	toggleButtonDisabled(nextBtnEl, currentSlide >= lastIndex);
}

/**
 *
 * @param {HTMLButtonElement} btnEl
 * @param {boolean} shouldDisable
 */
function toggleButtonDisabled(btnEl, shouldDisable) {
	if (shouldDisable) {
		btnEl.setAttribute('disabled', '');
	} else {
		btnEl.removeAttribute('disabled');
	}
}

/**
 * Toggle if an element should have a class
 *
 * @param {Element} el
 * @param {string} clazz
 * @param {boolean} shouldHaveClass
 */
function toggleClass(el, clazz, shouldHaveClass) {
	el.classList[shouldHaveClass ? 'add' : 'remove'](clazz);
}
