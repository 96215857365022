export function yearsFilter(el) {
	const buttonEL = document.createElement('button');
	buttonEL.innerHTML = el.dataset.btnLabel;
	buttonEL.classList.add('drop-list');
	buttonEL.setAttribute('aria-haspopup', 'true');
	buttonEL.setAttribute('aria-expanded', 'false');

	el.prepend(buttonEL);

	if (el.querySelector('.drop-list-nav')) {
		const navEL = el.querySelector('.drop-list-nav');
		navEL.classList.add('hidden');

		buttonEL.addEventListener('click', function() {
			const isExpanded = buttonEL.getAttribute('aria-expanded') === 'true';
			buttonEL.setAttribute('aria-expanded', (!isExpanded).toString());
			if (!isExpanded) {
				navEL.classList.remove('hidden');
			} else {
				navEL.classList.add('hidden');
			}
		});
	}
}
